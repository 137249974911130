import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: { name: 'login' }
    },
    {
      path: '/WIP',
      name: 'WIP',
      // route level code-splitting
      // this generates a separate chunk (WIP.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: function () {
        return import(/* webpackChunkName: "wip" */ '@/views/WIP.vue')
      }
    },
    {
      path: '/login', name: 'login', component: function () { return import('@/components/User/Login/login.component.vue') }
    },
    {
      path: '/holidays', name: 'holidays', component: function () { return import('./views/Holidays/holidays.list.vue') }
    },
    {
      path: '/payment', name: 'payment', component: function () { return import('./views/PaymentMethod/payment.method.vue') }

    },
    {
      path: '/monitoring', name: 'monitoring', component: function () { return import('./views/Monitoring/monitoring.list.vue') }

    },
    {
      path: '/typeVehicle', name: 'typeVehicle', component: function () { return import('./views/typeVehicle/type.vehicle.list.vue') }

    },
    {
      path: '/typeCommerce', name: 'typeCommerce', component: function () { return import('./views/typeCommerce/typeCommerce.list.vue') }

    },
    {
      path: '/commerceslist', name: 'CommercesList', component: function () { return import('./views/Commerce/commerce.list.vue') }

    },
    {
      path: '/zones', name: 'ViewZonesList', component: function () { return import('./views/Zone/zones.list.vue') }

    },
    {
      path: '/deliverymen', name: 'DeliverymenList', component: function () { return import('@/views/Deliveryman/delivery.list.vue') }
    },
    {
      path: '/services', name: 'DeliverymenServices', component: function () { return import('@/views/Deliveryman/services/delivery.services.list.vue') }
    },
    {
      path: '/settings', name: 'deliverymenSettings', component: function () { return import('@/views/Deliveryman/invoices/delivery.settings.list.vue') }
    },
    {
      path: '/rates', name: 'tarifas', component: function () { return import ('./views/Rates/RatesView.vue') }
    },
    {
      path: '/mesh', name: 'Mallas', component: function () { return import('@/views/Mallas/malla.list.vue') }
    },
    {
      path: '/UserList', name: 'userlist', component: function () { return import('@/views/User/user.list.vue') }
    },
    {
      path: '/RolsList', name: 'RolsList', component: function () { return import('./views/Rols/RolsList.vue') }
    },
    {
      path: '/DashboardServices', name: 'DashboardServices', component: function () { return import('./views/DashboardServices/DashboardServices.vue') }
    },
    {
      path: '/TypeDocsList', name: 'TypeDocsList', component: function () { return import('./views/Document/document.list.vue') }
    },
    {
      path: '/CitiesList', name: 'CitiesList', component: function () { return import('./views/City/cities.list.vue') }
    },
    {
      path: '/CountriesList', name: 'CountriesList', component: function () { return import('./views/Country/country.list.vue') }
    },
    {
      path: '/TypeInvoicesList', name: 'TypeInvoicesList', component: function () { return import('./views/invoice/type/type.invoice.vue') }
    },
    {
      path: '/BankTypeAccList', name: 'BankTypeAccList', component: function () { return import('./views/Bank/account/type.list.vue') }
    },
    {
      path: '/IvaRegimesList', name: 'IvaRegimesList', component: function () { return import('./views/Iva/iva.regimen.list.vue') }
    },
    {
      path: '/BanksList', name: 'BanksList', component: function () { return import('./views/Bank/banks/banks.list.vue') }
    },
    {
      path: '/ContactsList', name: 'ContactsList', component: function () { return import('./views/Contact/contact.list.vue') }
    },
    {
      path: '/modelMesh', name: 'ModelMallaCreate', component: function () { return import('./components/Mallas/createMalla/create.malla.vue') }
    },
    {
      path: '/dashboard', name: 'dashboard', component: function () { return import('./views/Dashboard/Dashboard.vue') }
    },
    {
      path: '/view/service/:uuid', name: 'viewService', component: function () { return import('@/views/Services/Service.vue') }
    },
    {
      path: '/typifications', name: 'typifications', component: function () { return import('./views/Typification/TypificationView.vue') }
    }
  ]
})
